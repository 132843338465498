import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from "gatsby"
import '../styles/main.scss';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout pageTitle={frontmatter.title}>
      <div className="page">
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div className="page-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($shortname: String!) {
    markdownRemark(frontmatter: { shortname: { eq: $shortname } type: { eq: "topic" } }) {
      html
      frontmatter {
        type
        category
        shortname
        title
      }
    }
  }
`
